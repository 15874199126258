import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Client } from '@hapi/nes/lib/client';
import { NotificationManager } from 'react-notifications';
import { PERFIL, PERMISSOES } from '../common/Constants';
import { VINCULO_PERFIL } from '../componentes/exame/constantes';
import { temPerfilRBAC, temPermissaoRBAC } from '../secutity/acl';
import { setAuthenticated as setAuthenticatedAction, setUser as setUserAction } from '../reducers/actions';
import { Switch, Route } from 'react-router-dom';
import Menu from '../componentes/menu';
import Pendencias from './pendencias';
import Solicitacao from './solicitacao';
import EnvioImagens from './envio-imagens';
import Exames from './exame';
import Laudo from './laudo';
import Erro401 from './erro/401';
import LaudosGraves from './laudos-graves';
import { SttCircularProgress, SttCacheManager, SttTranslate } from '@stt-componentes/core';
import { Cache } from '@stt-componentes/cache';
import ProtectedRoute from './protectedRoute';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		paddingBottom: theme.spacing(4)
	},
	loading: {
		display: 'flex',
		justifyContent: 'center',
		paddingTop: '300px'
	}
}));

const Interno = ({ strings, user, isAuthenticated, setAuthenticated, setUser }) => {
	const classes = useStyles();
	const history = useHistory();

	const [isSubscribed, setSubscribed] = useState(false);
	const [client] = useState(new Client(global.gConfig.ws_url_base_dermato));
	const [wsPath, setWsPath] = useState([]);

	const handler = (update, flags) => {
		// INÍCIO - Controle para abrir a aba de exames na instituicão correta
		let indice = 0;
		user.perfisRBAC
			.filter((p) => [PERFIL.TECNICO].includes(p.identificador))
			.forEach((perfil) => {
				if (perfil.vinculo === VINCULO_PERFIL.INSTITUICAO) {
					perfil.instituicoes.forEach((instituicao, i) => {
						if (instituicao.id === update.instituicao.id) {
							indice = i;
						}
					});
				}
			});
		// FIM - Controle para abrir a aba de exames na instituicão correta
		NotificationManager.error(`${strings.laudoEmitidoExame} ${update.exame} - ${update.dermatose}`, update.instituicao.nome, 10000, () => {
			history.push('/exames/visualizar', { exame: update.exame, aba: indice });
		});
	};

	useEffect(() => {
		if (user.perfisRBAC) {
			const perfisInteresse = user.perfisRBAC.filter((p) => [PERFIL.MEDICO_SOLICITANTE, PERFIL.TECNICO].includes(p.identificador));

			if (perfisInteresse.length) {
				const connect = async () => {
					await client.connect();
				};

				connect();
			}
			perfisInteresse.forEach(async (perfil) => {
				let paths = [];
				// INSTITUIÇÃO
				if (perfil.vinculo === VINCULO_PERFIL.INSTITUICAO) {
					for (let indice = 0; indice < perfil.instituicoes.length; indice++) {
						const instituicao = perfil.instituicoes[indice];
						const path = `/laudo-grave-tecnico/${instituicao.id}`;
						paths.push(path);
						console.debug(`Subscribing to ${path}`);
						client.subscribe(path, handler);
						setSubscribed(true);
					}

					setWsPath(paths);
				} else {
					if (temPerfilRBAC(user, PERFIL.MEDICO_SOLICITANTE)) {
						const path = `/laudo-grave-solicitante/${user.idFuncionario}`;
						paths.push(path);
						console.debug(`Subscribing to ${path}`);
						client.subscribe(path, handler);
						setSubscribed(true);
					}
				}
			});
		}
		return () => {
			if (isSubscribed) {
				wsPath.forEach((path) => {
					console.debug(`Unsubscribing from ${path}`);
					client.unsubscribe(path, null);
				});
			}
		};
	}, [user]);

	useEffect(() => {
		if (isAuthenticated && !temPermissaoRBAC(user, PERMISSOES.TELEDERMATO)) {
			window.location.href = `${global.gConfig.url_base_oauth2}/inicio`;
		}
	}, [isAuthenticated]);

	return (
		<div>
			{isAuthenticated ? (
				<div className={classes.wrapper}>
					<Menu />
					<Switch>
						<Route
							path="/nao-autorizado"
							exact
							component={Erro401}
						/>
						<Route
							path="/solicitacao"
							exact
							children={
								<ProtectedRoute
									component={Solicitacao}
									permissao={PERMISSOES.SOLICITAR_EXAME}
								/>
							}
						/>
						<Route
							path="/imagens"
							exact
							children={
								<ProtectedRoute
									component={EnvioImagens}
									permissao={PERMISSOES.VISUALIZAR_SOLICITACAO}
								/>
							}
						/>
						<Route
							path="/exames"
							children={
								<ProtectedRoute
									component={Exames}
									permissao={PERMISSOES.VISUALIZAR_EXAME}
								/>
							}
						/>
						<Route
							path="/laudos-graves"
							exact
							children={
								<ProtectedRoute
									component={LaudosGraves}
									perfil={[PERFIL.ADMINISTRADOR, PERFIL.ADMINISTRADOR_ESTADUAL]}
								/>
							}
						/>
						<Route
							path="/laudo"
							exact
							children={
								<ProtectedRoute
									component={Laudo}
									permissao={PERMISSOES.LAUDAR_EXAME}
								/>
							}
						/>
						<Route
							path="/"
							exact
							component={Pendencias}
						/>
					</Switch>
				</div>
			) : (
				<>
					<SttCacheManager
						config={global.gConfig}
						onSync={(loaded) => {
							const user = Cache.getUserInfo();
							setUser(user);
							setAuthenticated(loaded);
						}}
						hasExternalToken={true}
					/>
					<div className={classes.loading}>
						<SttCircularProgress />
					</div>
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.index.user,
		isAuthenticated: state.index.isAuthenticated
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setAuthenticated: (authenticated) => dispatch(setAuthenticatedAction(authenticated)),
		setUser: (user) => dispatch(setUserAction(user))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('LaudosGraves')(Interno));
