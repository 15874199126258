import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { getHeaders } from '../../request';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
import PrintIcon from '@material-ui/icons/Print';
import BlockIcon from '@material-ui/icons/Block';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WebIcon from '@material-ui/icons/Web';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Form from './formPesquisa';
import Moment from 'react-moment';
import Laudo from '../laudo';
import { setLaudar as setLaudarAction } from '../../reducers/actions/laudo';
import { setAtualizarBusca as setAtualizarBuscaAction } from '../../reducers/actions/exame';
import { TIPO_ABA_EXAME, SITUACAO_LAUDO } from './constantes';
import { MODALIDADE, PERFIL, PERMISSOES } from '../../common/Constants';
import { temPerfilRBAC, temPermissaoRBAC, temPermissaoRede } from '../../secutity/acl';
import Priorizar from './priorizar';
import Invalidar from './invalidar';
import TrocarRede from './trocarRede';
import {
	SttTable,
	SttTableHead,
	SttTableBody,
	SttTableRow,
	SttTableCell,
	SttTablePagination,
	SttCircularProgress,
	SttMenu,
	SttMenuItem,
	SttListItemIcon,
	SttListItemText,
	SttDivider,
	SttGrid,
	SttTranslate,
	SttCheckbox,
	SttButton,
	SttHidden,
	SttText
} from '@stt-componentes/core';
import { Chip, IconButton } from '@material-ui/core';
import { ListaExames } from '@stt-utilitarios/core';

const useStyles = makeStyles((theme) => ({
	tableWrapper: {
		marginTop: theme.spacing(3)
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	}
}));

const Pesquisa = (props) => {
	const {
		user,
		strings,
		tipo,
		id,
		laudador,
		laudar,
		filtros,
		setLaudar,
		atualizarBusca,
		setAtualizarBusca,
		imprimirFn,
		imprimirProtocoloFn,
		imprimirTermoFn
	} = props;
	const classes = useStyles();
	const location = useLocation();
	const history = useHistory();

	const [exames, setExames] = useState([]);
	const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
	const [page, setPage] = useState(filtros.page || global.gConfig.pagination.page);
	const [count, setCount] = useState(filtros.count || global.gConfig.pagination.count);
	const [totalRegistros, setTotalRegistros] = useState(0);
	const [colspan, setColspan] = useState(6);
	const [menuOptions, setMenuOption] = useState([]);
	const [idExameSelecionado, setIdExameSelecionado] = useState(null);

	// Priorização
	const [priorizar, setPriorizar] = useState(false);
	const [idInstituicaoExamePriorizar, setIdInstituicaoExamePriorizar] = useState(null);

	// Invalidação
	const [invalidar, setInvalidar] = useState(false);
	const [idInstituicaoExameInvalidar, setIdInstituicaoExameInvalidar] = useState(null);
	const [idModalidadeExameSelecionado, setIdModalidadeExameSelecionado] = useState(null);

	const [permissaoLaudoInvPrio, setPermissaoLaudoInvPrio] = useState([]);

	// Redes
	const [redes, setRedes] = useState([]);
	const [redeExameSelecionado, setRedeExameSelecionado] = useState(null);
	const [carregarRedes, setCarregarRedes] = useState(false);
	const [trocarRede, setTrocarRede] = useState(false);

	// Seleção de exames
	const [examesSelecionados, setExamesSelecionados] = useState([]);

	const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;

	const preInvalidar = (exame) => {
		setIdExameSelecionado(exame.id);
		setIdInstituicaoExameInvalidar(exame.id_instituicao);
		setIdModalidadeExameSelecionado(exame.id_modalidade);
		setInvalidar(true);
	};

	const prePriorizar = (exame) => {
		setIdInstituicaoExamePriorizar(exame.id_instituicao);
		setIdExameSelecionado(exame.id);
		setPriorizar(true);
	};

	const preTrocarRede = (exame) => {
		if (!Array.isArray(exame)) {
			setExamesSelecionados([exame.id]);
		}
		setRedeExameSelecionado(exame.id_rede_telemedicina);
		setTrocarRede(true);
	};

	useEffect(() => {
		if (carregarRedes) {
			axios
				.get(`${UTILITARIOS_API_BASE_URL}/rede?modalidade=${MODALIDADE.SIGLA}`, { headers: getHeaders() })
				.then((response) => {
					if (response.data) {
						const { itens } = response.data.data;
						setRedes(itens);
					}
				})
				.catch((err) => console.log(err));
		}
	}, [carregarRedes]);

	useEffect(() => {
		if (user.perfisRBAC) {
			let rede;
			user.perfisRBAC.forEach((p) => {
				p.redes &&
					p.redes.forEach((r) => {
						if (r.id === id) {
							rede = r;
						}
					});
			});
			if (rede) {
				setPermissaoLaudoInvPrio([
					temPermissaoRede(rede, PERMISSOES.INVALIDAR_EXAME),
					temPermissaoRede(rede, PERMISSOES.PRIORIZAR_EXAME),
					temPermissaoRede(rede, PERMISSOES.LAUDAR_EXAME)
				]);
			}
		}
	}, [user]);

	useEffect(() => {
		if (temPerfilRBAC(user, PERFIL.ADMINISTRADOR) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) {
			setColspan(10);
			setCarregarRedes(true);
		} else if (temPerfilRBAC(user, 'tecnico') || temPerfilRBAC(user, 'medicoLaudador') || temPerfilRBAC(user, 'medicoExecutor')) {
			setColspan(7);
		}

		let menu = [];
		if (temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_EXAME) || temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_LAUDO)) {
			menu.push({
				apenasExameValido: false,
				aplicaA: [
					SITUACAO_LAUDO.COM_LAUDO,
					SITUACAO_LAUDO.SEM_LAUDO,
					SITUACAO_LAUDO.LAUDO_TEMPORARIO,
					SITUACAO_LAUDO.LAUDO_EMISSAO,
					SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
					SITUACAO_LAUDO.DIGITADO,
					SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
					SITUACAO_LAUDO.AGUARDANDO_TRANSCRICAO
				],
				icon: VisibilityIcon,
				title: strings.ver,
				createOnClick: (exame) => {
					visualizarExame(exame.id);
				}
			});
		}
		if (permissaoLaudoInvPrio[2]) {
			menu.push({
				apenasExameValido: true,
				aplicaA: [
					SITUACAO_LAUDO.COM_LAUDO,
					SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
					SITUACAO_LAUDO.SEM_LAUDO,
					SITUACAO_LAUDO.LAUDO_TEMPORARIO,
					SITUACAO_LAUDO.LAUDO_EMISSAO,
					SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO
				],
				icon: DescriptionIcon,
				title: strings.laudo,
				createOnClick: (exame) => {
					setIdExameSelecionado(exame.id);
					setLaudar(true);
				}
			});
		}
		if (
			(tipo === TIPO_ABA_EXAME.REDE && permissaoLaudoInvPrio[0]) ||
			(tipo !== TIPO_ABA_EXAME.REDE && temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME))
		) {
			menu.push({
				apenasExameValido: true,
				aplicaA: [SITUACAO_LAUDO.SEM_LAUDO],
				icon: BlockIcon,
				title: strings.invalidar,
				createOnClick: (exame) => preInvalidar(exame)
			});
		}
		if (temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME_LAUDADO)) {
			menu.push({
				apenasExameValido: true,
				aplicaA: [SITUACAO_LAUDO.COM_LAUDO],
				icon: PriorityHighIcon,
				title: strings.invalidar,
				createOnClick: (exame) => preInvalidar(exame)
			});
		}
		if (
			(tipo === TIPO_ABA_EXAME.REDE && permissaoLaudoInvPrio[1]) ||
			(tipo !== TIPO_ABA_EXAME.REDE && temPermissaoRBAC(user, PERMISSOES.PRIORIZAR_EXAME))
		) {
			menu.push({
				apenasExameValido: true,
				aplicaA: [SITUACAO_LAUDO.SEM_LAUDO],
				icon: PriorityHighIcon,
				title: strings.priorizar,
				createOnClick: (exame) => prePriorizar(exame)
			});
		}
		if (temPermissaoRBAC(user, PERMISSOES.TROCAR_REDE)) {
			menu.push({
				apenasExameValido: true,
				aplicaA: [SITUACAO_LAUDO.SEM_LAUDO],
				icon: SwapHorizIcon,
				title: strings.trocarRede,
				createOnClick: (exame) => preTrocarRede(exame)
			});
		}
		menu.push({
			apenasExameValido: true,
			aplicaA: [
				SITUACAO_LAUDO.COM_LAUDO,
				SITUACAO_LAUDO.SEM_LAUDO,
				SITUACAO_LAUDO.LAUDO_TEMPORARIO,
				SITUACAO_LAUDO.LAUDO_EMISSAO,
				SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
				SITUACAO_LAUDO.DIGITADO,
				SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
				SITUACAO_LAUDO.AGUARDANDO_TRANSCRICAO
			],
			icon: PrintIcon,
			title: strings.imprimir,
			createOnClick: (exame) => imprimirFn(exame.id)
		});
		menu.push({
			apenasExameValido: true,
			aplicaA: [
				SITUACAO_LAUDO.COM_LAUDO,
				SITUACAO_LAUDO.SEM_LAUDO,
				SITUACAO_LAUDO.LAUDO_TEMPORARIO,
				SITUACAO_LAUDO.LAUDO_EMISSAO,
				SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
				SITUACAO_LAUDO.DIGITADO,
				SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
				SITUACAO_LAUDO.AGUARDANDO_TRANSCRICAO
			],
			icon: WebIcon,
			title: strings.protocolo,
			createOnClick: (exame) => imprimirProtocoloFn(exame)
		});
		menu.push({
			apenasExameValido: true,
			aplicaA: [
				SITUACAO_LAUDO.COM_LAUDO,
				SITUACAO_LAUDO.SEM_LAUDO,
				SITUACAO_LAUDO.LAUDO_TEMPORARIO,
				SITUACAO_LAUDO.LAUDO_EMISSAO,
				SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
				SITUACAO_LAUDO.DIGITADO,
				SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
				SITUACAO_LAUDO.AGUARDANDO_TRANSCRICAO
			],
			icon: AssignmentIcon,
			title: strings.imprimirTermo,
			createOnClick: (exame) => imprimirTermoFn(exame)
		});
		setMenuOption(menu);
	}, [permissaoLaudoInvPrio]);

	useEffect(() => {
		if (!laudar && !priorizar && !invalidar && !trocarRede) {
			setIdExameSelecionado(null);
			setIdInstituicaoExamePriorizar(null);
			setIdModalidadeExameSelecionado(null);
			setExamesSelecionados([]);
		}
	}, [laudar, priorizar, invalidar, trocarRede]);

	useEffect(() => {
		if (location.state?.from === 'visualizar') {
			setAtualizarBusca(true);
		}
	}, [location.pathname]);

	const visualizarExame = (id) => {
		history.replace('/exames', { from: 'visualizar' });
		history.push('/exames/visualizar', { exame: id });
		setLaudar(false);
	};

	const resetPageCount = () => {
		setPage(global.gConfig.pagination.page);
		setCount(global.gConfig.pagination.count);
		setExamesSelecionados([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		setAtualizarBusca(true);
	};

	const handleChangeRowsPerPage = (event) => {
		setCount(event.target.value);
		setPage(global.gConfig.pagination.page);
		setAtualizarBusca(true);
	};

	const callbackBusca = (dados) => {
		if (dados) {
			setTotalRegistros(parseInt(dados.totalRegistros));
			setExames(dados.itens);
		} else {
			setTotalRegistros(0);
			setExames([]);
		}
		setAtualizarBusca(false);
	};

	const callbackInvalidar = () => {
		setAtualizarBusca(true);
	};

	const selecionarTodosExames = (selecionar) => {
		if (!exames.length) {
			return;
		}
		// extrai o id dos exames da página
		const examesPagina = exames.map((e) => e.id);
		if (selecionar) {
			// adiciona os últimos selecionados (página atual) ao array de selecionados anteriormente (páginas anteriores)
			const selecionados = [...examesSelecionados, ...examesPagina];
			// remove duplicidades e atualiza o estado do componente
			setExamesSelecionados([...selecionados.filter((value, index, array) => array.indexOf(value) === index)]);
		} else {
			// mantém apenas os exames selecionados em páginas anteriores
			const selecionados = examesSelecionados.filter((e) => !examesPagina.includes(e));
			// atualiza o estado do componente
			setExamesSelecionados([...selecionados]);
		}
	};

	return (
		<>
			{laudar ? (
				<Laudo idExame={idExameSelecionado} />
			) : (
				<>
					<Form
						tipoAba={tipo}
						idAba={id}
						laudador={laudador}
						callbackBusca={callbackBusca}
						callbackAndamento={setBuscaEmAndamento}
						page={page}
						count={count}
						buscar={atualizarBusca}
						resetPageCount={resetPageCount}
						redes={redes}
					/>

					<SttGrid
						container
						spacing={3}
					>
						<SttGrid
							item
							xs={12}
						>
							<SttDivider />
							<div className={classes.tableWrapper}>
								<SttTable>
									{examesSelecionados.length > 0 && temPermissaoRBAC(user, PERMISSOES.TROCAR_REDE) && (
										<caption>
											<div className={classes.contentWrapper}>
												{examesSelecionados.length === 1 && `${examesSelecionados.length} ${strings.exameSelecionado}`}
												{examesSelecionados.length > 1 && `${examesSelecionados.length} ${strings.examesSelecionados}`}
												<SttButton
													type="button"
													variant="contained"
													color="secondary"
													onClick={() => preTrocarRede(examesSelecionados)}
												>
													{strings.trocarRede}
												</SttButton>
											</div>
										</caption>
									)}
									<SttTableHead>
										<SttTableRow>
											<SttHidden smDown>
												{temPermissaoRBAC(user, PERMISSOES.TROCAR_REDE) && (
													<SttTableCell>
														<SttCheckbox
															color="primary"
															checked={
																exames.length > 0 &&
																examesSelecionados.filter((e) => exames.map((e) => e.id).includes(e)).length ===
																	exames.length
															}
															onChange={(evt, value) => selecionarTodosExames(value)}
														/>
													</SttTableCell>
												)}
												<SttTableCell>{strings.dataExame}</SttTableCell>
												{(temPerfilRBAC(user, PERFIL.ADMINISTRADOR) ||
													temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) && (
													<>
														<SttTableCell>{`${strings.municipio}/${strings.uf}`}</SttTableCell>
														<SttTableCell>{strings.instituicao}</SttTableCell>
													</>
												)}
												{(temPerfilRBAC(user, PERFIL.ADMINISTRADOR) ||
													temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL) ||
													temPerfilRBAC(user, PERFIL.TECNICO) ||
													temPerfilRBAC(user, PERFIL.MEDICO_LAUDADOR)) && <SttTableCell>{strings.tecnico}</SttTableCell>}
												<SttTableCell>{strings.requisicao}</SttTableCell>
												<SttTableCell>{strings.paciente}</SttTableCell>
												<SttTableCell>{strings.descricaoExame}</SttTableCell>
												<SttTableCell>{strings.situacao}</SttTableCell>
												<SttTableCell align="center">{strings.opcoes}</SttTableCell>
											</SttHidden>
										</SttTableRow>
										<SttTableRow>
											<SttHidden mdUp>
												{temPermissaoRBAC(user, PERMISSOES.TROCAR_REDE) && (
													<SttTableCell>
														<SttCheckbox
															color="primary"
															checked={
																exames.length > 0 &&
																examesSelecionados.filter((e) => exames.map((e) => e.id).includes(e)).length ===
																	exames.length
															}
															onChange={(evt, value) => selecionarTodosExames(value)}
														/>
													</SttTableCell>
												)}
												<SttTableCell width="90%">{strings.dadosExame}</SttTableCell>
												<SttTableCell align="center">{strings.opcoes}</SttTableCell>
											</SttHidden>
										</SttTableRow>
									</SttTableHead>
									<SttTableBody>
										{buscaEmAndamento ? (
											<SttTableRow key={-1}>
												<SttTableCell
													colSpan={colspan}
													align="center"
												>
													<SttCircularProgress />
												</SttTableCell>
											</SttTableRow>
										) : exames.length === 0 ? (
											<SttTableRow key={-1}>
												<SttTableCell
													colSpan={colspan}
													align="center"
												>
													{strings.nenhumRegistroEncontrado}
												</SttTableCell>
											</SttTableRow>
										) : (
											exames.map((row, index) => (
												<SttTableRow key={index}>
													<SttHidden smDown>
														{temPermissaoRBAC(user, PERMISSOES.TROCAR_REDE) && (
															<SttTableCell>
																<SttCheckbox
																	color="primary"
																	checked={examesSelecionados.includes(row.id)}
																	onChange={(evt, value) => {
																		if (value) {
																			setExamesSelecionados([...examesSelecionados, row.id]);
																		} else {
																			setExamesSelecionados(examesSelecionados.filter((e) => e !== row.id));
																		}
																	}}
																/>
															</SttTableCell>
														)}
														<SttTableCell>{<Moment format="DD/MM/YYYY HH:mm">{row.data_exame}</Moment>}</SttTableCell>
														{(temPerfilRBAC(user, PERFIL.ADMINISTRADOR) ||
															temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) && (
															<>
																<SttTableCell>{`${row.municipio}/${row.uf}`}</SttTableCell>
																<SttTableCell>{row.instituicao}</SttTableCell>
															</>
														)}
														{(temPerfilRBAC(user, PERFIL.ADMINISTRADOR) ||
															temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL) ||
															temPerfilRBAC(user, PERFIL.TECNICO) ||
															temPerfilRBAC(user, PERFIL.MEDICO_LAUDADOR)) && (
															<SttTableCell>{row.funcionario_envio}</SttTableCell>
														)}
														<SttTableCell>{row.requisicao}</SttTableCell>
														<SttTableCell>{row.paciente}</SttTableCell>
														<SttTableCell>{row.descricao_exame}</SttTableCell>
														<SttTableCell>
															<Chip
																style={{ backgroundColor: ListaExames.getCorLegenda(row) }}
																label={ListaExames.getTextoLegenda(row, strings)}
																size="small"
															/>
														</SttTableCell>
														<SttTableCell align="center">
															{menuOptions.length > 0 && (
																<PopupState variant="popover">
																	{(popupState) => (
																		<>
																			<IconButton
																				aria-haspopup="true"
																				{...bindTrigger(popupState)}
																			>
																				<MoreVertIcon />
																			</IconButton>
																			<SttMenu {...bindMenu(popupState)}>
																				{menuOptions.map((item, idx) => {
																					if (
																						item.aplicaA.includes(row.situacao_laudo) &&
																						((row.situacao === 'V' && item.apenasExameValido) ||
																							!item.apenasExameValido)
																					) {
																						return (
																							<SttMenuItem
																								key={idx}
																								onClick={() => {
																									popupState.close();
																									item.createOnClick(row);
																								}}
																							>
																								<SttListItemIcon>
																									<item.icon />
																								</SttListItemIcon>
																								<SttListItemText primary={item.title} />
																							</SttMenuItem>
																						);
																					}
																				})}
																			</SttMenu>
																		</>
																	)}
																</PopupState>
															)}
														</SttTableCell>
													</SttHidden>
													<SttHidden mdUp>
														{temPermissaoRBAC(user, PERMISSOES.TROCAR_REDE) && (
															<SttTableCell>
																<SttCheckbox
																	color="primary"
																	checked={examesSelecionados.includes(row.id)}
																	onChange={(evt, value) => {
																		if (value) {
																			setExamesSelecionados([...examesSelecionados, row.id]);
																		} else {
																			setExamesSelecionados(examesSelecionados.filter((e) => e !== row.id));
																		}
																	}}
																/>
															</SttTableCell>
														)}
														<SttTableCell>
															<SttText size="small">
																<b>{strings.dataExame}: </b>
																<Moment format="DD/MM/YYYY HH:mm">{row.data_exame}</Moment>
															</SttText>
															<SttText size="small">
																<b>{`${strings.municipio}/${strings.uf}`}:</b> {`${row.municipio}/${strings.uf}`}
															</SttText>
															<SttText size="small">
																<b>{strings.instituicao}:</b> {row.instituicao}
															</SttText>
															<SttText size="small">
																<b>{strings.tecnico}:</b> {row.funcionario_envio}
															</SttText>
															<SttText size="small">
																<b>{strings.requisicao}:</b> {row.requisicao}
															</SttText>
															<SttText size="small">
																<b>{strings.paciente}:</b> {row.paciente}
															</SttText>
															<SttText size="small">
																<b>{strings.descricaoExame}:</b> {row.descricao_exame}
															</SttText>
															<SttText size="small">
																<b>{strings.situacao}:</b>
																<Chip
																	style={{ backgroundColor: ListaExames.getCorLegenda(row) }}
																	label={ListaExames.getTextoLegenda(row, strings)}
																	size="small"
																/>
															</SttText>
														</SttTableCell>
														<SttTableCell align="center">
															{menuOptions.length > 0 && (
																<PopupState variant="popover">
																	{(popupState) => (
																		<>
																			<IconButton
																				aria-haspopup="true"
																				{...bindTrigger(popupState)}
																			>
																				<MoreVertIcon />
																			</IconButton>
																			<SttMenu {...bindMenu(popupState)}>
																				{menuOptions.map((item, idx) => {
																					if (
																						item.aplicaA.includes(row.situacao_laudo) &&
																						((row.situacao === 'V' && item.apenasExameValido) ||
																							!item.apenasExameValido)
																					) {
																						return (
																							<SttMenuItem
																								key={idx}
																								onClick={() => {
																									popupState.close();
																									item.createOnClick(row);
																								}}
																							>
																								<SttListItemIcon>
																									<item.icon />
																								</SttListItemIcon>
																								<SttListItemText primary={item.title} />
																							</SttMenuItem>
																						);
																					}
																				})}
																			</SttMenu>
																		</>
																	)}
																</PopupState>
															)}
														</SttTableCell>
													</SttHidden>
												</SttTableRow>
											))
										)}
									</SttTableBody>
								</SttTable>
							</div>
						</SttGrid>
					</SttGrid>
					{exames.length > 0 ? (
						<>
							<SttTablePagination
								rowsPerPageOptions={[10, 20, 40]}
								component="div"
								count={totalRegistros}
								rowsPerPage={count}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								labelRowsPerPage={strings.linhasPorPagina}
								labelDisplayedRows={(params) => `${params.from}-${params.to} ${strings.de} ${params.count}`}
							/>
							{priorizar &&
								((tipo === TIPO_ABA_EXAME.REDE && permissaoLaudoInvPrio[1]) ||
									(tipo !== TIPO_ABA_EXAME.REDE && temPermissaoRBAC(user, PERMISSOES.PRIORIZAR_EXAME))) && (
									<Priorizar
										priorizar={priorizar}
										setPriorizar={setPriorizar}
										idExame={idExameSelecionado}
										idInstituicao={idInstituicaoExamePriorizar}
									/>
								)}
							{invalidar &&
								((tipo === TIPO_ABA_EXAME.REDE && permissaoLaudoInvPrio[0]) ||
									(tipo !== TIPO_ABA_EXAME.REDE && temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME))) && (
									<Invalidar
										invalidar={invalidar}
										setInvalidar={setInvalidar}
										idExame={idExameSelecionado}
										idInstituicao={idInstituicaoExameInvalidar}
										idModalidade={idModalidadeExameSelecionado}
										callback={callbackInvalidar}
									/>
								)}
							{trocarRede && temPermissaoRBAC(user, PERMISSOES.TROCAR_REDE) && (
								<TrocarRede
									exame={examesSelecionados}
									redes={redes}
									trocarRede={trocarRede}
									setTrocarRede={setTrocarRede}
									redeExameSelecionado={redeExameSelecionado}
									callback={setAtualizarBusca}
								/>
							)}
						</>
					) : null}
				</>
			)}
		</>
	);
};

Pesquisa.propTypes = {
	strings: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	tipo: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
	return {
		user: state.index.user,
		atualizarBusca: state.exame.atualizarBusca,
		laudar: state.laudo.laudar,
		filtros: state.exame.filtros
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setAtualizarBusca: (atualizar) => dispatch(setAtualizarBuscaAction(atualizar)),
		setLaudar: (laudar) => dispatch(setLaudarAction(laudar))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('Exame')(Pesquisa));
