import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Store } from './store';
import './styles.css';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import { SttThemeProvider } from '@stt-componentes/core';

import Home from './paginas';

class App extends Component {
	render() {
		return (
			<div>
				<NotificationContainer />
				<Provider store={Store}>
					<SttThemeProvider config={global.gConfig}>
						<Home />
					</SttThemeProvider>
				</Provider>
			</div>
		);
	}

	getChildContext() {
		return {
			modulo: this.props.modulo
		};
	}
}

App.childContextTypes = {
	modulo: PropTypes.string.isRequired
};

export default App;
